import React, {useContext, useEffect} from "react";
import endpoints from "./endpoints.json";
import { useAuth0 } from "@auth0/auth0-react";
import App from "./App";
import { AuthContext } from './components/AuthContext';

const Home = () => {
  const {
    error,
    isAuthenticated,
    user,
    loginWithRedirect,
    getAccessTokenSilently
  } = useAuth0();

  const authContext = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      if (isAuthenticated){
        const accessToken = await getAccessTokenSilently();
        authContext.setToken(accessToken);

      
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${accessToken}`);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            crossDomain:true,
            mode: "cors",
            referrerPolicy: "strict-origin-when-cross-origin"
        };
    
        var sessionName = user.email;
        var url = endpoints.sts + "rolesessionname=" + sessionName;
        fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
            authContext.setAWSCredentials(data.AssumeRoleResponse.AssumeRoleResult.Credentials);
        })
        .catch(error => console.log('error', error));
      }
    }

    fetchData();
  }, [isAuthenticated, getAccessTokenSilently]);

  if (error) {
    return <div class="login-container">Oops... {error.message}</div>;
  }

  return (
    <div>

    {!isAuthenticated && (
      <div class="login-container">
        <h2>Please log in to use the application!</h2>
        <button class="login-button" onClick={() => loginWithRedirect()}>Login</button>
      </div>
    )}


    {isAuthenticated && (
      <App/>
    )}

    </div>
  )
};

export default Home;
