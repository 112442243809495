import React, { useState } from 'react';

export const AuthContext = React.createContext({
    token: null,
    setToken: () => {},
    awscredentials: {},
    setAWSCredentials: () => {}
});

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [awscredentials, setAWSCredentials] = useState({});

    return (
        <AuthContext.Provider value = {{token, setToken, awscredentials, setAWSCredentials}}>
            {children}
        </AuthContext.Provider>
    );
};