import AWS from 'aws-sdk';
import { AuthContext } from './AuthContext';
import React, { useEffect, useContext, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

let s3;

export function GetS3Client(){
    return s3;
}

export function initializeS3Client(credentials, params, setS3Client){
    AWS.config.update({
        region: "us-east-1",
        accessKeyId: credentials.AccessKeyId,
        secretAccessKey: credentials.SecretAccessKey,
        sessionToken: credentials.SessionToken
    })
    
    const s3 = new AWS.S3(params)
    setS3Client(s3);
}

export function useS3Client(params) {
    const { isAuthenticated } = useAuth0();
    const [s3Client, setS3Client] = useState(null);

    if (isAuthenticated){
        const awsContext = useContext(AuthContext);
        const credentials = awsContext.awscredentials;
        useEffect(() => {
            initializeS3Client(credentials, params, setS3Client);
        }, [credentials])
    }

    return s3Client;
}