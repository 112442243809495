import React, { useState } from 'react';
import "./SegmentedButton.css";

function SegmentedButton({ options, defaultOption, onChange }) {
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onChange(option);
  };

  return (
    <div className="segmented-button-field">
      {options.map((option) => (
        <button
          key={option}
          className={`segmented-button ${selectedOption === option ? 'active' : ''}`}
          onClick={() => handleOptionClick(option)}
        >
          {option}
        </button>
      ))}
    </div>
  );
}

export default SegmentedButton;
