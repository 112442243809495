import { useState, useEffect, useContext } from "react";
import { useS3Client } from "./S3Client";
import { AuthContext } from "./AuthContext";
import Select from "react-select";
import "./BucketList.css";
import SegmentedButton from "./SegmentedButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import s3Config from "../s3Config.json";
const AWS = require("aws-sdk");

const BucketList = params => {
  const authContext = useContext(AuthContext);
  const bucketName = s3Config.bucketName;
  const s3 = useS3Client({
    bucket: `${bucketName}`,
    signatureVersion: "v4"
  });
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [listFiles, setListFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedSubFolder, setSelectedSubFolder] = useState(null);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [numberOfFiles, setNumberOfFiles] = useState(null);
  const currentDate = new Date();
  const minDate = new Date(
    currentDate.getFullYear() - 1,
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const options = ["Show total available files", "Download / View files"];
  const [selectedOption, setSelectedOption] = useState("");

  const [dataFetched, setDataFetched] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const generateSignedUrl = async (key, download = true) => {
    const params = {
      Bucket: bucketName,
      Key: key,
      Expires: 3600
    };

    if (download) {
      params.ResponseContentDisposition = `attachment; filename="${key}"`;
    }

    try {
      return s3.getSignedUrl("getObject", params);
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const downloadObject = async key => {
    const url = await generateSignedUrl(key);
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.download = key;
      link.click();
    }
  };

  const openFileInNewWindow = async key => {
    const url = await generateSignedUrl(key, false);
    if (url) {
      window.open(url, "_blank");
    }
  };

  const fetchFolders = async () => {
    try {
      const { CommonPrefixes } = await s3
        .listObjectsV2({
          Bucket: bucketName,
          Delimiter: "/"
        })
        .promise();
      const folderNames = CommonPrefixes.map(prefix =>
        prefix.Prefix.replace("/", "")
      );
      setFolders(folderNames);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFolderSelect = selectedOption => {
    setSelectedFolder(selectedOption);
    setSelectedSubFolder(null);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setNumberOfFiles(null);
    resetFilteredFiles();
  };

  const folderOptions = folders.map(folderName => ({
    value: folderName,
    label: folderName
  }));

  const subFolderOptions = [
    { label: "ExecutionSummaryLog", value: "ExecutionSummaryLog" },
    { label: "ProcessExecutionLog", value: "ProcessExecutionLog" }
  ];

  const handleSubFolderSelect = selectedOption => {
    setSelectedSubFolder(selectedOption);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setNumberOfFiles(null);
    resetFilteredFiles();
  };

  const handleOptionChange = async option => {
    try {
      setSelectedOption(option);
      setIsLoading(true);
      if (!dataFetched) {
        const prefix = `${selectedFolder.value}/${selectedSubFolder.value}/`;
        const pageSize = 1000;
        let continuationToken = null;
        const filteredFiles = [];
        do {
          const params = {
            Bucket: bucketName,
            Prefix: prefix,
            Delimiter: "/",
            ContinuationToken: continuationToken
          };

          const response = await s3.listObjectsV2(params).promise();

          const pageFiles = response.Contents.filter(file => {
            const fileName = file.Key.split("/").pop();
            const dateMatch = /\d{4}\.\d{2}\.\d{2}/.exec(fileName);

            if (dateMatch) {
              const fileDate = dateMatch[0];
              const fileDateObj = new Date(fileDate);

              const startDateObj = new Date(selectedStartDate);
              const endDateObj = new Date(selectedEndDate);

              return fileDateObj >= startDateObj && fileDateObj <= endDateObj;
            }
            return false;
          });

          filteredFiles.push(...pageFiles);

          continuationToken = response.NextContinuationToken;
        } while (continuationToken);
        filteredFiles.sort((a, b) => b.LastModified - a.LastModified);
        setFilteredFiles(filteredFiles);
        setNumberOfFiles(filteredFiles.length);
        setDataFetched(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartDateChange = date => {
    setSelectedStartDate(date);
    setDataFetched(false);
    setNumberOfFiles(null);
    setSelectedOption("");
  };

  const handleEndDateChange = date => {
    setSelectedEndDate(date);
    setDataFetched(false);
    setNumberOfFiles(null);
    setSelectedOption("");
  };

  const resetFilteredFiles = () => {
    setDataFetched(false);
    setFilteredFiles([]);
  };

  useEffect(
    () => {
      fetchFolders();
    },
    [s3, authContext.awscredentials]
  );

  if (!s3 || folderOptions.length === 0) {
    return (<div className="loading-container">
    <div className="spinner" />
    <div>Please wait while the data is fetched from the S3</div>
  </div>);
  } else {
    return (
      <div className="container">
        <div className="dropdown-container">
          <div className="field">
            {folderOptions.length > 0 &&
              <Select
                options={folderOptions}
                value={selectedFolder}
                onChange={handleFolderSelect}
                placeholder="Select a process"
                className="dropdown"
              />}
          </div>
          <div className="field">
            {selectedFolder &&
              <Select
                options={subFolderOptions}
                value={selectedSubFolder}
                onChange={handleSubFolderSelect}
                placeholder="Select a log type"
                className="dropdown"
              />}
          </div>
        </div>
        <div className="field">
          {selectedSubFolder &&
            <div className="date-picker-container">
              <div className="date-picker">
                <div className="date-picker-label">Start Date</div>
                <DatePicker
                  selected={selectedStartDate}
                  onChange={date => handleStartDateChange(date)}
                  minDate={minDate}
                  maxDate={new Date()}
                  placeholderText="MM/DD/YYYY"
                />
              </div>
              <div className="date-picker">
                <div className="date-picker-label">End Date</div>
                <DatePicker
                  selected={selectedEndDate}
                  onChange={date => handleEndDateChange(date)}
                  minDate={minDate}
                  maxDate={new Date()}
                  placeholderText="MM/DD/YYYY"
                />
              </div>
            </div>}
        </div>
        {selectedStartDate &&
        selectedEndDate &&
        selectedSubFolder &&
        selectedFolder
          ? <div className="option-container">
              <SegmentedButton
                options={options}
                defaultOption={selectedOption}
                onChange={handleOptionChange}
              />
            </div>
          : <div className="error-message">
              {" "}Please fill in all required fields{" "}
            </div>}
        <p> </p>
        {isLoading
          ? <div className="loading-container">
              <div className="spinner" />
              <div>Please wait while the data is fetched from the S3</div>
            </div>
          : selectedOption === "Show total available files" &&
            numberOfFiles !== null &&
            <div className="total-files-container">
              <p>
                Total Available Files: {numberOfFiles}
              </p>
            </div>}

        {selectedOption === "Download / View files" &&
          <div className="file-list-container">
            {filteredFiles.length > 0 &&
              <table>
                <tbody>
                  {filteredFiles.map(file =>
                    <tr key={file.Key}>
                      <td className="file-row">
                        <p>
                          {file.Key}
                        </p>
                        <button
                          className="download-button"
                          onClick={() => downloadObject(file.Key)}
                        >
                          {" "}Download{" "}
                        </button>
                        <button
                          className="open-button"
                          onClick={() => openFileInNewWindow(file.Key)}
                        >
                          {" "}View{" "}
                        </button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>}
          </div>}
      </div>
    );
  }
};

export default BucketList;
