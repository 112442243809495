import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import "@awsui/global-styles/index.css";
import Home from './Home';
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";
import { AuthProvider } from './components/AuthContext';
import './index.css';

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

ReactDOM.render(
    <Auth0Provider {...providerConfig}>
      <AuthProvider>
        <HashRouter>
            <Home/>
        </HashRouter>
    </AuthProvider>
    </Auth0Provider>,
    document.getElementById('root')
);
